<template>
  <div>
    <h2 class="jsmb-title-detail has-text-text is-size-4">Mes Messages</h2>
    <p v-if="discussions.length === 0">Vous n'avez aucun message.</p>
    <div class="message-looper" v-for="(discussion, index) in discussions" :key="'discussion-'+index">
      <div class="columns">
        <div class="column is-3 has-text-centered">
          <div class="columns">
            <div class="column is-3">
              <figure class="image is-48x48 is-horizontal-center">
                <img
                  :src="discussion.discussion_user.profil_picture!==null?discussion.discussion_user.profil_picture:profilPicture"
                  :alt="discussion.discussion_user.first_name"
                  :title="discussion.discussion_user.first_name"
                  class="is-rounded"
                >
              </figure>
            </div>
            <div class="column">
              <p class="jsmb-pseudo">{{discussion.discussion_user.first_name}}</p>
              <p class="jsmb-date-message">{{discussion.updated_at}}</p>
            </div>
          </div>
        </div>
        <div class="column is-3 has-text-centered-mobile">
          <div class="columns is-multiline jsmb-message-text">
            <div class="column is-12">
              <router-link :to="{name: 'Detail', params: {id:discussion.space_id, city:discussion.announcement_city.toLowerCase()}}" tag="a" push>
                <p class="jsmb-message-extrait">{{discussion.announcement_title}} - {{discussion.space_title}}</p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="column is-3 has-text-centered-mobile">
          <div class="columns is-multiline jsmb-message-text">
            <div class="column is-12">
              <p class="jsmb-message-extrait">{{discussion.message.content}}</p>
            </div>
            <div class="column is-12">
              <p class="jsmb-date-dernier-message">le {{discussion.message.created_at_date}} à {{discussion.message.created_at_time}}</p>
            </div>
          </div>
        </div>
        <div class="columns is-3 has-text-centered-mobile">
          <div class="columns is-multiline jsmb-message-buttons">
            <div class="column is-12">
              <router-link :to="{name: 'MessageDetail', params: {messageId:discussion.discussion_id}}" tag="a" class="jsmb-voir" push>Voir</router-link>
            </div>
            <div class="column is-12">
              <a class="jsmb-suppr" @click="removeDiscussion(discussion.discussion_id, index)">Supprimer</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column" v-if="totalDiscussions > perPageDiscussions">
      <b-pagination
        :total="totalDiscussions"
        :current.sync="currentDiscussions"
        :order="order"
        :size="size"
        :simple="true"
        :rounded="isRounded"
        :per-page="perPageDiscussions"
        @change="discussionPage">
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { GET_DISCUSSION_ACCOUNT, REMOVE_DISCUSSION } from '@/store/modules/database/actions/database.actions'

export default {
  name: 'Message',
  data () {
    return {
      totalDiscussions: 0,
      currentDiscussions: 1,
      perPageDiscussions: 10,
      profilPicture: require('./../../../assets/profil-modal.png'),

      order: 'is-right',
      size: '',
      isRounded: false,

      discussions: [],
    }
  },
  methods: {
    messagePage(value) {
      this.$store.dispatch(GET_DISCUSSION_ACCOUNT, {
        perPage: this.perPageDiscussions,
        page: value
      }).then(resp => {
        this.discussions = resp.items;
        this.totalDiscussions = resp.total;
      });
    },
    removeDiscussion(messageId, discussionIndex) {
      this.$store.dispatch(REMOVE_DISCUSSION,
        { discussionId: messageId }
      ).then(
        () => {
          this.discussions.splice(discussionIndex, 1)
        }
      );
    }
  },
  created() {
    // Get Discussion
    this.$store.dispatch(GET_DISCUSSION_ACCOUNT, {
      perPage: this.perPageDiscussions,
      page: 1
    }).then(resp => {
      this.discussions = resp.items;
      this.totalDiscussions = resp.total;
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .message-looper {
    border:1px solid #bfc1c5;
    padding:1rem;
    margin-bottom:1rem;
  }
  .jsmb-pseudo {
    color:#313131;
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .jsmb-date-message {
    color:#1b1c1c;
  }
  .jsmb-message-extrait,
  .jsmb-date-dernier-message {
    color:#818181
  }
  .jsmb-voir {
    color: #0ec0b6;
  }
  .jsmb-suppr {
    color: #df5051;
  }
  .is-horizontal-center {
    margin:0 auto
  }
  @media screen and (max-width:798px){
    .is-horizontal-center {
      margin:0rem auto 1rem
    }
    .jsmb-message-buttons,
    .jsmb-message-text {
      margin-top:1rem;
    } 
  }
</style>
